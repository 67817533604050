import {Controller} from "stimulus";

export default class AddableNestedAttributesController extends Controller {
  static targets = ["containerField", "template"];
  static values = {strictIndices: Boolean, useTemplate: Boolean};

  add() {
    if (this.hasContainerFieldTarget) {
      const newEl = this.templateElement().cloneNode(true);
      const newElIndex = this.templateIndex();

      newEl.querySelectorAll("input, textarea, select").forEach(input => {
        const isBoolean = ["checkbox", "radio"].indexOf(input.type) > -1;
        const isSelect = !!input.querySelector("option");

        // If we are using the template, we don't need to clear any value, just use the template as it is
        if (!this.useTemplateValue) {
          if (isBoolean) {
            input.checked = false; // Uncheck new radio/checkbox fields
          } else if (isSelect) {
            input.value = input.querySelector("option").value; // Select first option value
          } else {
            input.value = ""; // Empty any existing values from cloned node
          }
        }

        // Update index
        updateAttributeIndex(input, "name", newElIndex);
        updateAttributeIndex(input, "id", newElIndex);
      });

      newEl.querySelectorAll("label").forEach(label => {
        updateAttributeIndex(label, "for", newElIndex);
      });

      // Because existing Trix instances are being duplicated, we need to ensure the new DOM elements have a "blank" state
      if (newEl.querySelector("trix-editor")) {
        newEl.querySelectorAll("trix-editor").forEach(trixEl => {
          // Remove toolbar
          trixEl.parentElement.querySelector("trix-toolbar")?.remove();
          trixEl.removeAttribute("toolbar");

          // Update or reset attributes
          trixEl.setAttribute("input", trixEl.parentElement.querySelector("input").id);
          trixEl.removeAttribute("trix-id");
          updateAttributeIndex(trixEl, "id", newElIndex);
        });
      }

      // Remove any elements that are explictity not indended to be cloned
      newEl.querySelectorAll("[data-admin--addable-nested-attributes-remove]").forEach(el => {
        el.remove();
      });

      // Remove any validation error border styles & messages
      newEl.querySelectorAll(".border-warning").forEach(el => {
        el.className = el.className
          .replace("border-warning", "border-manatee-diet")
          .replaceAll("warning", "royal");
      });
      newEl.querySelectorAll("div.text-sm.text-warning").forEach((el) => {
        el.remove();
      });

      // Show if hidden
      newEl.classList.remove("hidden");
      newEl.removeAttribute("hidden");

      this.containerFieldTarget.appendChild(newEl);

      this.dispatch("add", {
        detail: {element: newEl},
        prefix: "tlw-addable-nested-attributes",
        bubbles: true,
      });
    } else {
      console.warn("Missing container field");
    }
  }

  // Return previous container field target, or fallback to template
  templateElement() {
    if (!this.useTemplateValue && this.hasContainerFieldTarget && this.containerFieldTarget.hasChildNodes()) {
      return this.containerFieldTarget.lastElementChild;
    } else if (this.hasTemplateTarget) {
      return this.templateTarget.content.firstElementChild;
    }
  }

  // We can use the number of children in our container, but mindful a possible
  // <template> might have taken index "0", so increment by 1 if present
  templateIndex() {
    const existingLength = this.containerFieldTarget.children.length;
    if (this.hasTemplateTarget && !this.strictIndicesValue) {
      return existingLength + 1;
    } else {
      return existingLength;
    }
  }
}

const updateAttributeIndex = (element, attribute, newIndex) => {
  const currentValue = element.getAttribute(attribute);

  if (currentValue && newIndex > 0) {
    element.setAttribute(attribute, replaceLastOccurence(currentValue, newIndex - 1, newIndex));
  }
};

const replaceLastOccurence = (string, toReplace, replaceWith) => {
  const lastIndex = string.lastIndexOf(toReplace);

  return string.substring(0, lastIndex) + replaceWith + string.substring(lastIndex + 1);
};
