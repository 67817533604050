import {Controller} from "stimulus";

export default class FlashController extends Controller<HTMLElement> {
  static values = {animate: Boolean};

  timeoutDuration = 10000;

  declare animateValue: boolean;
  declare removeTimeout: ReturnType<typeof setTimeout>;

  connect() {
    this.handleSetTimeout();
    document.addEventListener("turbo:morph", this.handleRefresh);
    document.addEventListener("tlw-flash:refresh", this.handleRefresh);
  }

  disconnect() {
    this.handleRemoveTimeout();
    document.removeEventListener("turbo:morph", this.handleRefresh);
    document.removeEventListener("tlw-flash:refresh", this.handleRefresh);
  }

  hide = () => {
    this.element.remove();
  };

  private handleSetTimeout = () => {
    if (this.animateValue) {
      this.removeTimeout = setTimeout(this.hide, this.timeoutDuration);
    }
  };

  private handleRemoveTimeout = () => {
    if (this.removeTimeout) clearTimeout(this.removeTimeout);
  };

  private handleResetAnimation = () => {
    this.element.style.animation = "none";
    this.element.offsetHeight; // eslint-disable-line @typescript-eslint/no-unused-expressions -- trigger reflow
    this.element.style.removeProperty("animation");
  };

  private handleRefresh = () => {
    this.handleRemoveTimeout();
    this.handleResetAnimation();
    this.handleSetTimeout();
  };
}
